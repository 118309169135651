<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image searchengine-bg"
      data-black-overlay="6"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <h2 class="heading-title" style="color:#fff;">Search Engine</h2>
              <p>
                
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <h2 class="heading-title">효율적인 대용량 데이터</h2>
                        <h2 class="heading-title">관리를 위한 검색 엔진 최적화</h2>
                        <p class="description pt--80" align="justify">
                          Big Data를 활용하는 프로젝트에서, 대규모 데이터의 검색 및 색인화 작업은 프로젝트 성공의 핵심 요소입니다. 이러한 과정에서 강력하고 신뢰할 수 있는 검색 플랫폼의 선택은 매우 중요합니다. Solr와 Elastic Search와 같은 고성능 오픈 소스 검색 엔진들은 대규모 데이터셋을 효율적으로 처리하고, 사용자 요구에 맞춘 유연하고 정교한 검색 결과를 제공합니다. 이들 플랫폼은 높은 확장성과 안정성을 제공하며, 다양한 형태의 데이터를 신속하게 처리할 수 있는 강점을 갖고 있습니다.
                        </p>
                        <p class="description" align="justify">
                          프로젝트의 목표와 요구 사항에 따라 적합한 검색 솔루션을 선택하고 구현함으로써, 데이터 관리의 효율성을 극대화하고, 사용자 경험을 향상시키는 것이 중요합니다. 이러한 접근 방식은 빅 데이터를 기반으로 한 프로젝트의 성공을 위한 기본적이면서도 필수적인 전략이 됩니다.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12 mt--30 mb--120">
                    <div class="thumb position-relative">
                      <div class="thumbnail position-relative mb--60">
                        <img class="w-100" src="../../assets/images/technology/searchengine03.png" alt="" />
                      </div>
                      <div class="thumbnail position-relative">
                        <img class="w-100" src="../../assets/images/technology/searchengine01.png" alt="" />
                      </div>
                    </div>
                  </v-col>
                  
                  <v-col lg="12" md="12" cols="12">
                  </v-col>
                </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="rn-service-details ptb--120 bg_color--5" style="display:none;">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" order="2" order-md="1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <h2 class="heading-title">메인추천</h2>
                <p class="description">
                  사용자의 관심데이터와 트렌드를 바탕으로 메인화면을 통해 다양한 기업을 추천해 줍니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="2" cols="12" order="1" order-md="2">
            <div class="thumb position-relative">
              <img
                class="w-100" src="../../assets/images/service/service-02.png"
                alt="Service Images"
              />
            </div>
          </v-col>
          
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      Header,
      Footer,
      PricingPlan,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
